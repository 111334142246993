import * as React from "react";

export const VirtualEventLink = ({ link, enabled }: { link?: string; enabled: boolean; }) => {
  return enabled ? (
    <div className="THIS IS GETTING OVER WRITTEN BY GATSBY SOME HOW!!!!">
      <div className="my-4 grid gap-2 grid-cols-[auto_1fr] bg-transparent">
        <span className="px-2 py-1 text-sm font-bold text-white rounded-full bg-quaternary">Virtual Event</span>
        <a href={link}>{link}</a>
      </div>
    </div>
  ) : null;
};
