import * as React from "react";
import type { Event as EventType } from "@/types/graphql";
import { MapEmbed } from "@components/MapEmbed";
import { DynamicComponent } from "@components/DynamicComponent";
import { External } from "@components/Link";
import { DateField } from "./DisplayItem";
import { Heading } from "@components/Heading";
import { VirtualEventLink } from "../components/VirtualEventLink";

export type EventProps = {
  event: EventType;
};

const Info = ({ children }: { children: React.ReactNode }) => {
  return <p className="text-lg font-medium text-gray-800">{children}</p>;
};

export const PanelEvents = ({ event }: EventProps) => {
  return (
    <div className="p-4 my-4 rounded-lg bg-sky-100">
      <Heading level={2} className="leading-tight">
        {event.title}
      </Heading>

      <DateField
        startDate={event.date}
        endDate={event.endDate}
        timezone={event.timezone}
        displayTime={event.displayTime ?? true}
      />

      <VirtualEventLink link={event.virtualEventLink} enabled={event.virtualEvent!} />

      {event.eventLink && (
        <Info>
          A session at{" "}
          <External href={event.eventLink} className="text-quaternary">
            {event.eventName}
          </External>
        </Info>
      )}

      {event.presenters && (
        <Info>
          Participants: <span className="font-medium">{event.presenters}</span>
        </Info>
      )}

      <br />
      <DynamicComponent blocks={event._rawDescription} />
      <br />
      {event.location && <MapEmbed title={event.title} coordinates={[event.location.lat, event.location.lng] as any} />}

      {event.link && (
        <External className="text-quaternary" href={event.link}>
          Visit Event
        </External>
      )}
    </div>
  );
};
