import * as React from "react";
import { graphql, Link } from "gatsby";
import { isBefore } from "date-fns";
import { RenderEvents } from "@components/RenderEvents";
import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { heroAtom, seoAtom } from "@/store";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { Event } from "@/types/graphql";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";

export type EventsPageProps = {
  pageContext: {
    archive?: boolean;
  };
  data?: {
    allSanityEvent: {
      edges: { node: Event }[];
    };
  };
};

export const EventsPage = (props: EventsPageProps) => {
  const { archive } = props.pageContext;
  // Filter out the events that have already occurred.
  // TODO: Can this be done in the GraphQL query??
  const events = React.useMemo(() => {
    const items =
      props.data?.allSanityEvent.edges.filter((event) => {
        const hasOccured = isBefore(new Date(event.node.date), Date.now());
        return archive ? hasOccured : !hasOccured;
      }) || [];
    return archive ? items.reverse() : items;
  }, [props.data?.allSanityEvent.edges, archive]);
  useHydrateAtoms([
    [heroAtom, undefined],
    [seoAtom, { title: "EP3 Events", description: `View ${archive ? "past" : "upcoming"} EP3 events.` }],
  ]);
  return (
    <>

      <Helmet>
        <title> Events | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <Title> EP3 Events </Title>
          </Header>
          <RenderEvents events={events} pastEvents={archive} />
          {
            archive === true
              ? <Link to="/events">Current Events</Link>
              : <Link to="/events/archive">Previous Events</Link>
          }
        </Container>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query EventPageQuery {
    allSanityEvent(sort: { fields: date, order: ASC }) {
      edges {
        node {
          virtualEventLink
          virtualEvent
          eventName
          eventLink
          presenters
          title
          authors {
            name
            slug {
              current
            }
          }
          template
          location {
            lng
            lat
          }
          _rawDescription
          date
          endDate
          displayTime
          timezone
        }
      }
    }
  }
`;
export default EventsPage;
