import * as React from "react";
import { Event } from "./Event";
import type { Event as EventType } from "../../types/graphql";

export type RenderEventsProps = {
  pastEvents?: boolean;
  /**
   * Optionally render children
   */
  children?: React.ReactNode;
  /**
   * Optionally provide additional classNames to be applied to the component
   */
  className?: string;
  /**
   * Optionally provide a style object to be merged with the component styles
   */
  style?: React.CSSProperties;
  events: { node: EventType }[];
};

export const RenderEvents = ({ className = "", style = {}, events = [], pastEvents = false }: RenderEventsProps) => {
  return (
    <div className={`${className} mt-4`} style={style}>
      <p className="inline-block px-2 py-1 mb-2 font-semibold text-gray-500 rounded-full bg-sky-100">
        {events.length} {pastEvents ? "past" : "upcoming"} event{events.length === 1 ? "" : "s"}
      </p>
      <ul className="list-none">
        {events.map((event: any) => {
          return (
            <li key={event.node.title}>
              <Event event={event.node} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
