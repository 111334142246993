import * as React from "react";
import { PanelEvents } from "./templates/PanelEvents";
import { WorkshopEvents } from "./templates/WorkshopEvents";
import { PosterEvents } from "./templates/PosterEvents";
import { CompactSessionEvents } from "./templates/CompactSessionEvents";
import { EventProps, SessionEvents } from "./templates/SessionEvents";
import { PlenaryEvents } from "./templates/PlenaryEvents";

export const Event = ({ event }: EventProps) => {
  switch (event.template) {
    case "poster":
      return <PosterEvents event={event} />;
    case "panel":
      return <PanelEvents event={event} />;
    case "workshop":
      return <WorkshopEvents event={event} />;
    case "compactSession":
      return <CompactSessionEvents event={event} />;
    case "plenary":
      return <PlenaryEvents event={event} />;
    default:
      return <SessionEvents event={event} />;
  }
};
