import * as React from "react";
import { formattedDate, formattedTime } from "@/utils";

const DisplayItem = ({ show, children }: { show: boolean; children: any }) => (show ? children : null);
export const DateField = ({
  startDate,
  endDate,
  timezone,
  displayTime,
}: {
  startDate: string | Date;
  endDate?: string | Date;
  timezone?: string;
  displayTime: boolean;
}) => {
  if (!startDate) return null;
  const formattedStartDate = formattedDate(startDate, timezone);
  const formattedEndDate = endDate ? formattedDate(endDate, timezone) : null;

  const formattedStartTime = formattedTime(startDate, timezone);
  const formattedEndTime = endDate ? formattedTime(endDate, timezone) : null;

  const sameDate = formattedStartDate === formattedEndDate;

  return endDate && !sameDate ? (
    <p className="text-lg font-semibold">
      <div className="flex items-center" style={{ lineHeight: "0.5em" }}>
        <span className="py-2">{formattedStartDate}</span>
        <DisplayItem show={displayTime}>
          <span className="px-2 text-quaternary">{" | "}</span>
          <span className="py-2">
            {formattedStartTime} {timezone}
          </span>
        </DisplayItem>
        <span className="p-2">–⁠</span>
        <span className="py-2">{formattedEndDate}</span>
        <DisplayItem show={displayTime}>
          <span className="px-2 text-quaternary">{" | "}</span>
          <span className="py-2">
            {formattedEndTime} {timezone}
          </span>
        </DisplayItem>
      </div>
    </p>
  ) : sameDate ? (
    <p className="flex items-center text-lg font-semibold">
      <span className="py-2">{formattedStartDate}</span>
      <DisplayItem show={displayTime}>
        <span className="px-2 text-quaternary">{" | "}</span>
        <span className="py-2">{formattedStartTime}</span>
        {sameDate && <span className="pl-1">–⁠</span>}
        {sameDate && <span className="pl-1">{formattedEndTime} </span>}
        <span className="pl-1">{timezone}</span>
      </DisplayItem>
    </p>
  ) : (
    <p className="flex items-center text-lg font-semibold">
      <span className="py-2">{formattedStartDate}</span>
      <DisplayItem show={displayTime}>
        <span className="px-2 text-quaternary">{" | "}</span>
        <span className="py-2">
          {formattedStartTime} {timezone}
        </span>
      </DisplayItem>
    </p>
  );
};
